import React from 'react'
import { PropTypes } from 'prop-types'

//Bootstrap
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

class BlogBody extends React.Component {
    render(){
        return <Container className='mt-5 pt-5'>
            <Row className='d-flex justify-content-center bg-white shadow-1 rounded mt-0 mt-lg-4 mb-5'>
                <Col as='article' lg={9} className='col-lg-9 pt-4 pb-5 pt-lg-5'>
                    {this.props.children}
                </Col>
            </Row>
        </Container>
    }
}
BlogBody.propTypes = {
    children: PropTypes.node.isRequired,
}

export default BlogBody
