import React from 'react'
import { Link } from 'gatsby'
import { PropTypes } from 'prop-types'

// components
import Breadcrumb from '../../components/Breadcrumb/breadcrumb'

class BlogHeader extends React.Component {
    render(){
        const regex = new RegExp('[ ,]', 'g')
        return <>
            <Breadcrumb linkClasses={'text-secondary'} hideHomeLink={true} listClasses={'mb-0 pb-0'}links={[
                {
                    name: `Back to ${this.props.slug}`,
                    destination: `/${this.props.slug}`,
                    last: false
                },
            ]}/>
            <h1 className='qs-blog__title'>{this.props.title}</h1>
            <div className='qs-article-item__meta d-flex align-items-center flex-wrap'>
                <div>Last updated: {this.props.createdDate} &nbsp;<span className='d-none d-md-inline'>|</span>&nbsp;</div>
                <div>
                  Tags: 
                    {this.props.topic && <Link className={'qs-article-item__tag'} to={`/${this.props.slug}/${this.props.topic.topicName.toLowerCase().replace(regex, '-')}`}>{this.props.topic.topicName}</Link>}
                    {this.props.topicCategory && <Link className={'qs-article-item__tag'} to={`/${this.props.slug}/${this.props.topicCategory.topicCategory.toLowerCase().replace(regex, '-')}`}>{this.props.topicCategory.topicCategory}</Link>}
                    <Link className='qs-article-item__tag' to={`/${this.props.slug}`}>
                            Exam resource
                    </Link>
                </div>
                {
                    this.props.examBoard &&
                    <div className='w-100'>
                    Exam boards: {this.props.examBoard.map((exam) => (<Link key={exam.contentful_id} className='qs-article-item__tag' to={`/${this.props.slug}/${exam.name}`}>{exam.name}</Link>))}
                    </div> 
                }
            </div>           
            <hr />
        </>
    }
}

BlogHeader.propTypes = {
    title: PropTypes.string,
    createdDate: PropTypes.string,
    tags: PropTypes.array,
    slug: PropTypes.string,
    className: PropTypes.string,
    topic: PropTypes.obj,
    topicCategory: PropTypes.obj,
    examBoard: PropTypes.array,

}

export default BlogHeader
