import * as React from 'react'
import { graphql } from 'gatsby'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import ReactPlayer from 'react-player/lazy'

//Katex
import renderMathInElement from 'katex/dist/contrib/auto-render'
import 'katex/dist/katex.min.css'

// components
import SEO from '../../components/SEO/seo'
import Layout from '../../components/Layout/layout'
import GetStarted from  '../../components/GetStarted/getStarted'
import BlogHeader from '../../components/Blog/blogHeader'
import BlogBody from '../../components/Blog/blogBody'

//constants
import { NOTES_SLUG } from '../../utils/constants'

class BlogPost extends React.Component { 

  _renderLatex = () => {
      renderMathInElement(this.katexField)
  }

  componentDidMount() {
      this._renderLatex()
  }

  componentDidUpdate() {
      this._renderLatex()
  }
    
  render ({data}) {
      const Bold = ({ children }) => <strong className='bold'>{children}</strong>
      const Text = ({ children }) => <p className='qs-blog__p'>{children}</p>

      const options = {
          renderMark: {
              [MARKS.BOLD]: text => <Bold>{text}</Bold>,
          },
          renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
              [BLOCKS.HEADING_2]: (node, children) => <h2 className='qs-blog__h2'>{children}</h2>,
              [BLOCKS.HEADING_3]: (node, children) => <h3 className='qs-blog__h3'>{children}</h3>,
              [BLOCKS.HEADING_4]: (node, children) => <h4 className='qs-blog__h4'>{children}</h4>,
              [BLOCKS.EMBEDDED_ASSET]: node => {
                  const dimensions = node.data.target.file.details.image
                  const altText = node.data.target.title
                  const src = node.data.target.file.url
                  return (
                      <>
                          <img className={`${dimensions.width > 300 ? '' : ''} w-100 h-50`} style={{maxWidth: '100%'}}width={dimensions.width} height={dimensions.height} alt={altText} src={src}/>
                      </>
                  )
              }
          },
      }

      const notes = data.contentfulNotes
      return <>
          <SEO title={data.contentfulNotes.title} description={data.contentfulNotes.description.description}/>
          <Layout>
              <BlogBody>
                  <BlogHeader
                      title={notes.title}
                      createdDate={notes.createdAt}
                      tags={notes.metadata.tags}
                      slug={NOTES_SLUG}
                      topic={notes.topic}
                      topicCategory={notes.topicCategory}
                      examBoard={notes.examBoard}
                  />
                  {console.log(notes.examBoard)}
                  { notes.videoLink && <>
                      <div>
                          <div className='qs-blog__video-wrapper  d-flex align-items-center justify-content-center'>
                              <ReactPlayer url={notes.videoLink} light={true} className='react-player ' width={'100%'} height={'100%'}/>
                          </div> 
                      </div>
                      <hr className='w-100 mt-5' />
                  </>
                  }
                  <div ref={ref => this.katexField = ref}>
                      {renderRichText(notes.content, options)}
                  </div>
              </BlogBody>
              <GetStarted
                  background='bg-light-yellow'
                  description={'Everything you need to get an A+ in IGCSE, GCSE and O-Level Maths.'}
                  secondaryButtonText={`Just answer questions and you will get an A - A* - guaranteed`}

              />
          </Layout>
      </>
  }    
}

export const query = graphql`
query MyQuery($id: String) {
  contentfulNotes(id: {eq: $id}) {
    slug
    title
    createdAt(formatString: "MMMM D, YYYY")
    description {
      description
    }
    videoLink
    content {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          title
          file {
            details {
              image {
                height
                width
              }
            }
            contentType
            url
          }
        }
      }
    }
    topicCategory {
      topicCategory
      contentful_id
    }
    topic {
      topicImageSource
      topicName
      contentful_id
    }
    examBoard {
      name
      contentful_id
    }
    metadata {
      tags {
        name
        id
      }
    }
  }
}

`

export default BlogPost